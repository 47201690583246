<template>
  <span v-if="!link" :class="classes">
    <slot />
  </span>
  <NuxtLink :to="link" v-if="link" :class="classes">
    <slot />
  </NuxtLink>
</template>

<script setup>
  defineProps(["link"]);
  var classes = computed(()=>{
    return "paragraph-intro display-6 text-primary text-decoration-none me-3 align-text-bottom";
  });
</script>
